.slider-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  position: relative;
  height: 400px;
  overflow: hidden;
  @media screen and (min-width: 821px) {
    overflow: unset;
  }
  .slide {
    position: absolute;
    background-color: var(--background-primary);
    border-radius: 15px;
    width: 100%;
    flex: 0 0 auto;
    opacity: 0;
    transition: all 0.7s ease-in-out;
    z-index: -1;
    transform: translateX(0);
    scale: 0.3;
    height: fit-content;
    .slide-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1em;
      @media screen and (min-width: 821px) {
        flex-direction: row;
      }
    }
    &.prev {
      transform: translateX(50%);
      scale: 0.3;
      opacity: 0;
      z-index: 2;
      @media screen and (min-width: 821px) {
        opacity: 1;
      }
    }
    &.next {
      transform: translateX(-50%);
      scale: 0.3;
      opacity: 0;
      z-index: 2;
      @media screen and (min-width: 821px) {
        opacity: 1;
      }
    }
    &.active {
      transform: translateX(0);
      scale: 0.9;
      z-index: 3;
      opacity: 1;
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
    }
  }
}

.circle-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.slider-button {
  margin-top: 24px;
  background-color: var(--white);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 1px solid var(--background-primary);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rating {
  font-size: 24px;
}
