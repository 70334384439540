.slider-container {
  height: 400px;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 821px) {
  .slider-container {
    overflow: unset;
  }
}

.slider-container .slide {
  background-color: var(--background-primary);
  width: 100%;
  opacity: 0;
  z-index: -1;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 15px;
  flex: none;
  transition: all .7s ease-in-out;
  position: absolute;
  transform: translateX(0)scale3d(.3, .3, 1);
}

.slider-container .slide .slide-content {
  flex-direction: column;
  justify-content: center;
  gap: 1em;
  display: flex;
}

@media screen and (min-width: 821px) {
  .slider-container .slide .slide-content {
    flex-direction: row;
  }
}

.slider-container .slide.prev {
  opacity: 0;
  z-index: 2;
  transform: translateX(50%)scale3d(.3, .3, 1);
}

@media screen and (min-width: 821px) {
  .slider-container .slide.prev {
    opacity: 1;
  }
}

.slider-container .slide.next {
  opacity: 0;
  z-index: 2;
  transform: translateX(-50%)scale3d(.3, .3, 1);
}

@media screen and (min-width: 821px) {
  .slider-container .slide.next {
    opacity: 1;
  }
}

.slider-container .slide.active {
  z-index: 3;
  opacity: 1;
  transform: translateX(0)scale3d(.9, .9, 1);
  box-shadow: 0 0 30px #0003;
}

.circle-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.slider-button {
  background-color: var(--white);
  width: 50px;
  height: 50px;
  border: 1px solid var(--background-primary);
  cursor: pointer;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  display: flex;
}

.rating {
  font-size: 24px;
}

/*# sourceMappingURL=slider.47b7ad98.css.map */
